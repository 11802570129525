// some common things used on the cloud portal page

// Global object for containing various things.
// TODO maybe add a lot of stuff into this
// CP stands for Cloud Portal
const CP = {};

// Clicks 'fileInput' element so a file selection dialog is opened
const selectFiles = function()
{
	let fileInput = document.getElementById('fileInput');
	fileInput.click();
};

const getel  = function(id)
{
	return document.getElementById(id);
};

const crel = function(type)
{
	return document.createElement(type);
};

const crelAdv = (type, className, parentElement) => {
	let el = document.createElement(type);
	if (className !== null) el.className = className;
	if (parentElement !== null) parentElement.appendChild(el);
	return el;
};

// Redirecto to the manage group page
const goToGroupPage = function()
{
	window.location = "index.html";
	window.body.load();
};

// Code for handling a page that should only be visible when connected to a group
const handleNonPublicPage = function() {
	// Redirect to manage group page if not connected to group
	if (!isLinked()) goToGroupPage();
};

// Check url to see if this is the beta program
// We use a different url for the beta program
// whose path is /beta
const isBetaInUrl = () => {
	return (window.location.pathname === '/beta');
};

const isBeta = () => {
	if (isBetaInUrl()) return true;
	let groupInfo = retrieveEncryptionKey();
	return (groupInfo && groupInfo.version !== null && groupInfo.version === Constants.CurrentVersion);
};

// Do some initialization that is common in all pages of the cloud portal
const initPage = (options) => {
	// by default public is false
	if (!options || !(options.publicPage)) handleNonPublicPage();
	showSceneTabIfScenePresent();
	addGroupIdToTabLinksIfNeeded();
	getel('cloudPortalLink').innerHTML = "Cloud" + (isBeta() ? ' <span class="font-bold">Beta</span>' : "");
};

// Different styles for text bubbles
// These are actually just lists of classes
const TextBubbleStyle = {
	Simple: 'textbubble',
	Notification: 'textbubble notification',
	Progress: 'textbubble notification--progress',
	Error: 'textbubble notification notification--error',
	Warning: 'textbubble notification notification--warning'
};

// For creating various types of text bubbles
const TextBubble = function (parentElement, text, textBubbleStyle, title, enableRemoval) {
	let div = crelAdv('div', textBubbleStyle, null);
	let divInner = crelAdv('div', 'flex-1', div);

	if (title !== null) {
		let h2 = crelAdv('h2', 'notification__title', divInner);
		h2.textContent = title;
	}

	let p = crelAdv('p', 'notification__body', divInner);
	p.textContent = text;

	if (enableRemoval) {
		let button = crel('button');
		button.textContent = "Dismiss";
		button.onclick = function () { div.remove(); };
		div.appendChild(button);
	}

	parentElement.appendChild(div);

	// Sets the text of the text bubble
	// text : the text
	// allowHTML : If true, HTML will be allowed inside of the text
	// If this is not needed, this should be set to false for security reasons
	this.setText = (text, allowHTML) => {
		if (allowHTML) p.innerHTML = text;
		else p.textContent = text;
	};

	// Set style of the text bubble. See TextBubbleStyle
	this.setStyle = (textBubbleStyle) => { div.className = textBubbleStyle; };
};

// Message manager
// Object containing things for showing messages on the cloud portal
CP.MsgManager = {};

// Initialize the message manager
// errorArea: An element on the page where error and warning messages are shown.
// staticMsgArea: An element on the page where static messages are shown, that is
// messages that don't change.
// notificationArea: An element on the page where notification messages are shown.
CP.initMsgManager = (errorArea, staticMsgArea, notificationArea) => {
	CP.MsgManager.errorArea = errorArea;
	CP.MsgManager.staticMsgArea = staticMsgArea;
	CP.MsgManager.notificationArea = notificationArea;
};

CP.showErrorMessage = (message) => {
	// If CP.MsgManager.errorArea is not set, this will result in an error
	TextBubble(CP.MsgManager.errorArea, message, TextBubbleStyle.Error, 'Error', true);
};

CP.showWarningMessage = (message) => {
	// If CP.MsgManager.errorArea is not set, this will result in an error
	TextBubble(CP.MsgManager.errorArea, message, TextBubbleStyle.Warning, 'Warning', true);
};

CP.notify = (text, progressing) => {
	let style = progressing ? TextBubbleStyle.Progress : TextBubbleStyle.Notification;
	if (!CP.MsgManager.notificationBubble)
		CP.MsgManager.notificationBubble = new TextBubble(CP.MsgManager.notificationArea, text, style, null, false);
	else {
		CP.MsgManager.notificationBubble.setText(text, false);
		CP.MsgManager.notificationBubble.setStyle(style);
	}
};

// Add static text to page
// text : The text to add, can contain HTML
CP.addStaticText = (text) => {
	let bubble = new TextBubble(CP.MsgManager.staticMsgArea, "", TextBubbleStyle.Simple, null, false);
	bubble.setText(text, true);
};

// checks if HTML web storage is supported in the browser
const isStorageSupported = function() {
	if (typeof(Storage) !== "undefined") {
		console.log("Storage is supported.");
		return true;
	}
	else {
		console.warn("Storage is not supported.");
		return false;
	}
};

// Get the (encryption key)/(group id) from the URL bar in the browser
// Returns it as a base64 encoded string
const getEncryptionKeyFromUrl = function(parName) {
	try {
		return Util.getUrlPar(window.location, parName);
	}
	catch (error) {
		console.error("Unexpected error while trying to get group ID from URL!", error);
		return null;
	}
};

// Get the (encryption key)/(group id)
// Also, if the version could not be determinied locally,
// makes a request to index file to check the version
// according to the index file. If an index file can be found
// then the version string is updated accordingly
// This is mainly needed when getting the group id from the url.
// Then the version is not known, but a way to get it is to
// try getting the index file using the group id or the hashed group id.
// This can be done by calling Cloud.getIndexFromCloud with version set
// to 0 and 1.
const retrieveEncryptionKeyAndCheckVersion = async() => {
	let groupInfo = retrieveEncryptionKey();
	if (groupInfo !== null && groupInfo.version === null) {
		let vers = Constants.CloudSystemVersionZero;
		let res = await Cloud.getIndexFromCloud({ groupID: groupInfo.groupID, version: vers});
		if (res.success) { groupInfo.version = vers; }
		vers = Constants.CurrentVersion;
		res = await Cloud.getIndexFromCloud({ groupID: groupInfo.groupID, version: vers});
		if (res.success) { groupInfo.version = vers; }
	}
	return groupInfo;
};

// Converts a group ID from base64 form to the binary representation
// Returns null if there is any kind of failure to parse or extract the group ID
// from it's base64 representation
const convertGroupIDFromBase64 = (base64) => {
	// The group ID base64 code should always be of this length
	if (base64 && (base64.length === 43 || base64.length === 44)) {
		try {
			let grid = CryptoUtil.base64ToBytes(base64);
			if (grid && grid.length === 32) return grid;
		} catch (error) {
			console.error("Failed to convert base64 string to group ID : ", error);
		}
	}
	return null;
};

// Try Retrieving encryption key from URL or storage
// If it can't be retrieved, null is returned
const retrieveEncryptionKey = function() {

	// Cloud group version
	let vers = null;
	// First try getting it from the URL
	let base64 = getEncryptionKeyFromUrl('groupid');

	// If we can't get it from the URL then try getting it from local storage
	if (base64 === null) {
		try {
			if (isStorageSupported()) {
				base64 = localStorage.getItem(Constants.EncryptionKeyItemName);
				vers = localStorage.getItem(Constants.CloudGroupVersionItemName);

				if (base64 === null || base64 === '' || base64 === 'null') return null;
				// If the key can be retrieved from local storage but not the version
				// we assume the group version is 0, because before version 1, there
				// was no version string available
				if (vers === null || vers === '' || vers === 'null') vers = Constants.CloudSystemVersionZero;
			}
		} catch (error) {
			console.error("Unexpected error while trying to retrieve encryption key from local storage: ", error);
		}
	}

	let encrKeyBytes = convertGroupIDFromBase64(base64); // Convert it to bytes
	return encrKeyBytes ? { groupID : encrKeyBytes, version : vers} : null;
};

// Try storing encryption key in browser storage
// for later use
// encrKey: The encryption key, also called group ID
// version: The cloud group version.
const storeEncryptionKey = function(encrKey, version)
{
	if (isStorageSupported()) {
		localStorage.setItem(Constants.EncryptionKeyItemName, CryptoUtil.bytesToBase64(encrKey));
		if (version) localStorage.setItem(Constants.CloudGroupVersionItemName, version);
	}
};

const isLinked = () => Boolean(retrieveEncryptionKey()?.groupID);

const makeElementHidden = function(el)
{
	el.classList.add('hidden');
};

const makeElementVisible = function(el)
{
	el.classList.remove('hidden');
};

const setVisibility = function(el, visibility) {
	if (visibility) makeElementVisible(el);
	else makeElementHidden(el);
};

// Get elements within a specific element using a query
const getElements = function(id, query) {
	return document.getElementById(id).querySelectorAll(query);
};

// Get all the navigation tab links
const getTabLinks = function() {
	return getElements("navlist", "li > a");
};

// Get all the navigation tab links except the one for the group page
const getNonGroupTabLinks = function() {
	return getElements("navlist", "li > a:not(#indexLink)");
};

// Get tab navigation list items other than the one for the group page
const getNonGroupTabListItems = function() {
	return getElements("navlist", "li:not(#indexListItem)");
};

// If a group id is in the URL of the page, then add that to the tab links
const addGroupIdToTabLinksIfNeeded = function () {
	let groupId = getEncryptionKeyFromUrl('groupid');
	if (groupId != null) {
		let links = getTabLinks();
		for (let i = 0; i < links.length; i++)
			links[i].href = Util.addOrUpdateUrlParameter(links[i].href, 'groupid', groupId);
	}
};

// Remove entry from index file in cloud
// entryHash : hash of the entry
// onError : method to call if there is an error
const removeEntryFromIndex = async function (entryHash, onError)
{
	let result = {};
	result.success = false;

	if (!isLinked())
	{
		goToGroupPage();
		return result;
	}

	try {
		let groupInfo = await retrieveEncryptionKeyAndCheckVersion();
		console.log('Removing entry from index file.');
		result = await Cloud.removeFromIndex(entryHash, groupInfo);
	} catch(err) {
		console.error('Error in removeEntryFromIndex:', err);
	}

	if (!result.success)
	{
		if (onError != null)
		{
			onError('A failure occured while trying to remove entry!');
		}
	}

	return result;
};

// For downloading a cloud resource in a browser
// Opens a save file dialog
// entryData : object containing entry and its hash
// nrOfParts : number of parts for multipart download
// filename : filename to show
// onError : Method to call when there is an error
// TODO refactor: We can get filename from entryData
const downloadCloudObject = async(entryData, nrOfParts, totalNrOfBytes, filename, onError, onWarning) =>
{
	if (!isLinked()) {
		goToGroupPage();
		return;
	}

	try {
		if (totalNrOfBytes > 200000000)
			if (onWarning != null)
				onWarning('Files are larger than 200MB, download might fail.');

		let objectName = Cloud.getEntryObjectName(entryData.entryValue, entryData.entryHash);

		let groupInfo = retrieveEncryptionKey();
		let encrkey = Cloud.getEntryObjectEncryptionKey(entryData.entryValue, groupInfo.groupID);

		let success = false;
		if (encrkey) {
			console.log('Getting object ' + objectName + ' from cloud.');
			const getRes = await Cloud.getCloudFile(objectName, encrkey, nrOfParts, groupInfo.version);
			if (getRes.success) {
				console.log('Got file from cloud.');
				var filenameWithoutExt = Util.getFilenameWithoutExtension(filename);
				Cloud.saveBytesAsFile(getRes.decryptedBytes, "application/zip", "Arkio_" + filenameWithoutExt + ".zip");
				success = true;
			} 
		}
		if (!success) if (onError != null) onError('Failed to download file from cloud!');
	} catch(err) {
		console.error('Error in downloadCloudObject', err);
		if (onError != null)
			onError('A failure occured while trying to download a file from the cloud!');
	}
};

// Creates a "button" cell in a html table
// Used for creating download and remove buttons
// text : The text on the button. The button with the text is not included is this is null.
// iconHTML : html code for an icon to show. Icon is not included if this is null.
// action : Function to call when user clicks on the button
// hideIconOnWiderScreens : If true, icon will only be shown on smaller screens such as for mobile devices
// tooltip : A tooltip to show for the button
const createButtonTD = function(text, iconHTML, action, hideIconOnWiderScreens, tooltip)
{
	let td2 = document.createElement('td');
	let td2div = document.createElement('div');
	td2div.setAttribute('class', 'flex justify-end');
	
	if (text) {	
		let td2a = document.createElement('a');
		td2a.setAttribute('href', '#');
		td2a.setAttribute('class', 'btn btn-small hidden md:inline');
		td2a.onclick = action;
		if (tooltip) td2a.setAttribute('title', tooltip);
		let td2span = document.createElement('span');
		td2span.textContent = text;
		td2a.appendChild(td2span);
		td2div.appendChild(td2a);
	}

	if (iconHTML) {
		let td2a2 = crel('a');
		td2a2.setAttribute('href', '#');
		if (hideIconOnWiderScreens) td2a2.setAttribute('class', 'inline md:hidden w-4');
		td2a2.onclick = action;
		td2a2.innerHTML = iconHTML;
		if (tooltip) td2a2.setAttribute('title', tooltip);
		td2div.appendChild(td2a2);
	}

	td2.appendChild(td2div);

	return td2;
};

const getFilenamePlusExtra = function(tableDataItem, showFileCount) {
	let entry = tableDataItem.entryData.entryValue;
	let filename = tableDataItem.name;
	let fileCount = 0;
	if (entry.File != null) {
		filename = entry.File.Filename;
		fileCount = entry.File.FileCount;
	}
	// filename column
	let filenamePlusExtra = '';
	if (filename) filenamePlusExtra = Util.getFilenameFromPath(filename);

	// Try getting the width of the section
	let contentWidth = 0;
	try
	{
		// TODO Assuming here that there is an element with id contentSection
		// on the page. Try to find a better solution for doing this.
		let contentSection = document.getElementById('contentSection');
		let positionInfo = contentSection.getBoundingClientRect();
		contentWidth = positionInfo.width;
	}
	catch(error)
	{
		console.error('Error when trying to get width of element', error);
	}

	if (contentWidth === 0)
	{
		// In case we can't get the width of the element then we let
		// the width of the page control how many characters are shown
		// So this will work well different screen sizes
		contentWidth = document.documentElement.scrollWidth;
	}

	let maxLen = Math.floor(contentWidth/20);

	if (filenamePlusExtra) {
		if (showFileCount && fileCount > 1)
		{
			filenamePlusExtra = filenamePlusExtra + " and " + (fileCount - 1) + " other file(s)";
		}
		if (filenamePlusExtra.length > maxLen)
		{
			filenamePlusExtra = filenamePlusExtra.substring(0, maxLen-3) + "...";
		}
	}
	return filenamePlusExtra;
};

// Get the created date as a string ready to display in the UI
const getCreatedDateForUI = function(tableDataItem) {
	let entry = tableDataItem.entryData.entryValue;
	let created = entry.Created;
	// Reformatting the date string a bit
	let createdDateForUI = '';
	if (created) {
		let month = created[0] + created[1];
		let day = created[3] + created[4];
		let year = created[6] + created[7] + created[8] + created[9];
		createdDateForUI = year + '/' + month + '/' + day + created.substring(10);
	}
	return createdDateForUI;
};

// Create a row in the entries table
// tableDataItem: Object with entry info
//
// options can have the following properties:
// options.enableDownload: If true, a download button will be added for each row
// options.enableRemove : If true, a remove button will be added for each row
// options.showFileCount: If true, then number of extra files will be shown in the filename field
// options.onEntryRemoved : Method to call after an entry has successfully been removed
// options.alwaysUseIcons : If true, all buttons will be icons
// options.hideStatusIcon : If true, the status/type icon will not be shown. By default, it is shown.
// 
// callbacks can contain the following functions:
// callbacks.onErr: Method to call when there is an error
// callbacks.onWarn: Method to call when a warning is to be shown
// callbacks.onEntryRemoved: Method to call after an entry has successfully been removed
const createTableRow = function(tableDataItem, options, callbacks) {
	
	let entry = tableDataItem.entryData.entryValue;
	let entryHash = tableDataItem.entryData.entryHash;
	let filename = tableDataItem.name;
	let status = tableDataItem.status;
	let nrOfParts = 1;
	let totalNrOfBytes = 0;
	let filenamePlusExtra = getFilenamePlusExtra(tableDataItem, options.showFileCount);
	let createdDateForUI = getCreatedDateForUI(tableDataItem);
	let fileIcon = null;
	let isFromACC = entry.ACC_AutomationJob ? true : false;

	if (entry.File != null) {
		nrOfParts = entry.File.NrOfParts;
		totalNrOfBytes = entry.File.TotalNrOfBytes;
		filename = entry.File.Filename;
		fileIcon = entry.File.Icon;
	}

	// the table row
	let tr = document.createElement('tr');
	// add some hidden data in the row, that will be used
	tr.dataset.entryHash = entryHash;
	tr.dataset.status = status;
	tr.dataset.filenameplusextra = filenamePlusExtra;
	tr.dataset.createddateforui = createdDateForUI;

	if (options.hideStatusIcon !== true) {
		let iconTD = crel('td');

		let iconId = null;
		if (status === 'uploading' ||  status === 'starting' || status === 'pending' || status === 'inprogress') iconId = 'SpinnerIcon';
		if (status === 'success') {
			iconId = 'ModelIcon';
			if (isFromACC) iconId = 'ACCIcon';
			else if (fileIcon) {
				if (fileIcon === Constants.Icon.Images) iconId = 'ImageIcon';
				if (fileIcon === Constants.Icon.Revit) iconId = 'RevitIcon';
				if (fileIcon === Constants.Icon.Rhino) iconId = 'RhinoIcon';
				if (fileIcon === Constants.Icon.SketchUp) iconId = 'SketchupIcon';
				if (fileIcon === Constants.Icon.Unity) iconId = 'UnityIcon';
			}
		}
		if (status === 'cancelled' ||
			status === 'failedLimitProcessingTime' ||
			status === 'failedDownload' ||
			status === 'failedInstructions' ||
			status === 'failedUpload' ||
			status === 'failedUploadOptional') iconId = 'ErrorIcon';

		if (iconId) iconTD.innerHTML = getel(iconId).innerHTML;

		tr.appendChild(iconTD);
	}

	let td0 = document.createElement('td');
	let td0div = document.createElement('div');
	td0div.setAttribute('class', 'flex items-center gap-2 max-w-2xs sm:max-w-none');
	let td0span = document.createElement('span');
	td0span.setAttribute('class', 'truncate flex-1');
	td0span.textContent = filenamePlusExtra;
	//td0div.appendChild(td0icon);
	td0div.appendChild(td0span);
	td0.appendChild(td0div);

	// date column
	let td1 = document.createElement('td');
	td1.textContent = createdDateForUI;

	tr.appendChild(td0);
	tr.appendChild(td1);

	if (options.includeStatus) {
		let td1_0 = crel('td');
		td1_0.textContent = status;
		tr.appendChild(td1_0);
	}

	if (options.enableSharing) 
		tr.appendChild(createButtonTD(options.alwaysUseIcons ? null : 'Share', getel("ShareIcon").innerHTML,
			((entryData) => { return () => callbacks.onShareButtonClicked?.(entryData); })(tableDataItem.entryData),
			false, "Share outside of group."));

	if (options.enableDownload)
		// download column
		// Get the html code for the download icon
		tr.appendChild(
			createButtonTD(options.alwaysUseIcons ? null : 'Download', getel("DownloadCircleIcon").innerHTML,
			((entryData, nrOfParts, totalNrOfBytes, filename, onError, onWarning) => {
				return () => {
					downloadCloudObject(entryData, nrOfParts, totalNrOfBytes, filename, onError, onWarning);
				};
			})(tableDataItem.entryData, nrOfParts, totalNrOfBytes, filename, callbacks.onErr, callbacks.onWarn),
			true && !(options.alwaysUseIcons), "Download this resource to your machine.")
		);

	if (options.enableRemove)
		// Getting icon html in similar way as with the download icon
		tr.appendChild(
			createButtonTD(null, getel("TrashIcon").innerHTML, ((hash, onError) => {
				return async () => {
					if (confirm("Remove this uploaded file?") === true) {
						let res = await removeEntryFromIndex(hash, onError);
						if (res.success) callbacks.onEntryRemoved?.(res.indexFile);
						else callbacks.onErr?.('There was an error when trying to remove an entry.');
					};
				};
			})(entryHash, callbacks.onErr), false, "Permanently delete this resource.")
		);

	return tr;
};

// Fill a table with cloud entries
// tableBody: table body html element
// tableData: An object containing the data that will be put into the table
// See createTableRow for details on the arguments options, onErr, onWarn and onEntryRemoved
const fillTableWithEntries = function(tableBody, tableData, options, callbacks) {
	tableBody.innerHTML = '';
	let n = tableData.length;
	for (let i = 0; i < n; i++) {
		let tr = createTableRow(tableData[i], options, callbacks);
		// Add row to body of table
		tableBody.appendChild(tr);
	}
};

// For filtering and sorting cloud entries
const filterAndSortEntries = function(entries, filterMethod) {
	entries = entries.filter(filterMethod);
	entries = Cloud.sortEntriesByDate(entries);
	return entries;
};

// Update table showing entries and more with new data
// Does not destroy the table body and re-create it as
// is done in fillTableWithEntries
const updateTableWithData = function(tableBody, tableData, options, callbacks) {

	// REMARK
	// Using Set and Map data structures here to make this faster.
	// Might be better to pass them along as arguments
	// to prevent memory problems.
	// It looks to me though that memory consumption
	// is not that much of a problem here.

	// See if there are entry hashes in current html table
	// That cannot be found in tableData
	const entryHashes = new Set();
	for (let i = 0; i < tableData.length; i++) entryHashes.add(tableData[i].entryData.entryHash);
	
	// REMARK
	// Might be better to pass this as an argument
	// instead of creating a new Map object all the time
    const rowsByEntryHash = new Map();
    const rows = tableBody.rows;
	let maxIndex = rows.length - 1;
	// Here we start at the last row and go backwards, to avoid
	// index shifting issues when rows are removed.
    for (let i = maxIndex; i >= 0; i--) {
        const rowEntryHash = rows[i].dataset.entryHash;  // Access the data-entryHash attribute
        if (rowEntryHash) {
			// If the entry hash of the row is not found in the new data
			// then it should be removed
			if (!entryHashes.has(rowEntryHash)) rows[i].remove();
			else rowsByEntryHash.set(rowEntryHash, rows[i]);
		}
    }
	maxIndex = tableData.length - 1;
	for (let i = maxIndex; i >= 0; i--) {
		let entryHash = tableData[i].entryData.entryHash;
		// If the item is not already in the table
		// then add it to the table, else
		// update the item in the table with the new data
		// but only if data needs to be updated.
		if (!rowsByEntryHash.has(entryHash)) {
			let tr = createTableRow(tableData[i], options, callbacks);
			if (tableBody.firstChild) tableBody.insertBefore(tr, tableBody.firstChild);
			else tableBody.appendChild(tr);
		} else {
			let rowToReplace = rowsByEntryHash.get(entryHash);
			// Check if replacement is needed
			// Best to only modify the row if it needs to be modified.
			let modNeeded = false;
			if (tableData[i].status !== rowToReplace.dataset.status) modNeeded = true;
			if (getFilenamePlusExtra(tableData[i], options.showFileCount) !== rowToReplace.dataset.filenameplusextra) modNeeded = true;
			if (getCreatedDateForUI(tableData[i]) !== rowToReplace.dataset.createddateforui) modNeeded = true;

			if (modNeeded) {
				let tr = createTableRow(tableData[i], options, callbacks);
				rowToReplace.replaceWith(tr);
			}
		}
	}
};

// get index file if possible
// returns index file object if it was possible to get it
// else returns null
// need to be connected to group
// if not connected, this will redirect to the group page
const getIndexFile = async function(onError)
{
	if (!isLinked())
	{
		goToGroupPage();
	}
	else
	{
		let groupInfo = await retrieveEncryptionKeyAndCheckVersion();
		let indexRes = await Cloud.getIndexFromCloud(groupInfo);

		if(!indexRes.success)
		{
			if (indexRes.httpStatusCode === 404)
			{
				// This should not happen since the index file should
				// always exist after a group has been created.
				console.error('No index file in cloud!');
			}
			else
			{
				console.error('Failed to download index file from cloud!');
				if (onError) onError("Failed to download records from cloud!");
			}
		}
		else
		{
			let indexFile = indexRes.indexFile;
			return indexFile;
		}
	}
	return null;
};

// TODO use MsgManager or at least TextBubble here
// Updates uploading progress/complete messages
// to show either a progressing or upload complete message
// error : set this to true if an error has happened
// If error is true then an upload complete message won't
// be shown although an progressing message could be
// shown if there are still files being processed.
// error: If true then the upload complete message
// will not be shown, even if the nr of files being sent
// has reached 0
const updateUploadingMessages = function(error, totalNrOfFilesBeingSent, nrOfOngoingUploadTasks)
{
	let uploadComplete = document.getElementById('uploadCompleteNotificationDiv');
	let uploadProgress = document.getElementById('uploadProgressNotificationDiv');

	if (nrOfOngoingUploadTasks === 0)
	{
		makeElementHidden(uploadProgress);
		if (!error)
		{
			makeElementVisible(uploadComplete);
		}
	}
	else
	{
		let extraUploadText = 'Please do not leave this page until the uploads have finished.';
		makeElementHidden(uploadComplete);
		let uploadProgressBody = document.getElementById('uploadProgressNotificationBody');
		if (totalNrOfFilesBeingSent > 0)
		{
			uploadProgressBody.textContent = 'Uploading ' + totalNrOfFilesBeingSent + ' files. ' + extraUploadText;
		}
		else
		{
			uploadProgressBody.textContent = 'Uploading. ' + extraUploadText;
		}
		makeElementVisible(uploadProgress);
	}
};

// Opens save dialog for user to save file to disk
// buffer : A buffer with data to save
// filename : Name of the file to save
const saveBufferToDisk = function(buffer, filename)
{
	var blob = new Blob([buffer], { type: 'application/octet-stream' });
	var link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = filename;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

// Create the data needed for filling the tables that are shown
// in the UI
// entries : Entries from the Cloud index file
// ongoingUploadTasks : Uploading tasks that are currently being processed
// on the cloud portal
const createTableData = async function(entries, ongoingUploadTasks) {

	let tableData = [];

	// Add the ongoing upload tasks (Files that are currently being processed uploaded,
	// whose entries are still not in the Cloud)
	if (ongoingUploadTasks)
		for (const eHash in ongoingUploadTasks) {

			// Not sure if it's possible for this to happen but adding this check to be safe
			// We don't want to show a processing row for an entry that's already in the cloud
			if (eHash in entries) continue;

			let task = ongoingUploadTasks[eHash];
			tableData.push({ name : task.Filename, status : 'uploading', isOngoingUploadTask : true,
				entryData : { entryHash : eHash, entryValue : {}}});
		}

	if (entries && entries.length > 0) {
		// Get info on design automation items
		let jobIds = '';
		for (const key in entries) {
			let entry = entries[key];
			if (entry.entryValue && entry.entryValue.ACC_AutomationJob) {
				if (jobIds.length > 0) jobIds += ',';
				jobIds += entry.entryValue.ACC_AutomationJob.JobId;
			}
		}
		let jobInfo = null;
		if (jobIds.length > 0) {
			jobInfo = await AccServices.getJobInfo(jobIds);
		}

		for (const key in entries) {
			let entry = entries[key];
			if (entry.entryValue) {
				let entryValue = entry.entryValue;
				if (entryValue.File)
					tableData.push({ name : entryValue.File.Filename, status : 'success', entryData : entry });
				if (entryValue.ACC_AutomationJob) {
					let jobId = entryValue.ACC_AutomationJob.JobId;
					if (jobId in jobInfo)
						tableData.push({ name : jobInfo[jobId].filename, status : jobInfo[jobId].status, entryData : entry });
				}
			}
		}
	}
	return tableData;
};

// fill html table with entry data from index file
// tableBody: html table body element to put data into
// entryFilterFunc: Function for filtering entries, controls what entries are shown in the table
// ongoingUploadTasks: upload tasks that are currently processing if any. If there are none, then set this to null
// fillTableFunc: A function that takes care of filling or updating the table
const getEntriesAndFillTable = async function(tableBody, entryFilterFunc, ongoingUploadTasks, fillTableFunc)
{
	try {
		let indexFile = await getIndexFile(CP.showErrorMessage);
		if (indexFile != null) {
			let entries = indexFile.GetEntriesAsArray();
			entries = filterAndSortEntries(entries, entryFilterFunc);
			let tableData = await createTableData(entries, ongoingUploadTasks);
			console.log(tableData);
			fillTableFunc(tableBody, tableData);
		}
	} catch (error) {
		console.error("Failure when trying to fill table!", error);
		CP.showErrorMessage("An unexpected error occurred while trying to fill table with data!");
	}
};

const getCurrentUrlWithoutPars = function() { return window.location.origin + window.location.pathname };

const logOutOfACC = async function() {
	// Remove token from local storage
	let redirected = false;
	let tokenData = AccUtil.retrieveAccessTokenDataFromLocalStorage();
	localStorage.setItem(Constants.AutodeskUserAccessTokenItemName, null);
	if (tokenData) {
		// Revoke the token
		try {
			await AccServices.revokeToken(tokenData.access_token, tokenData.refresh_token);
		} catch (error) {
			console.error('Failed to revoke access token: ', error);
		}
		// Log user out of Autodesk
		try {
			const url = 'https://developer.api.autodesk.com/authentication/v2/logout?' +
			'&post_logout_redirect_uri=' + getCurrentUrlWithoutPars();
			location.href = url;
			redirected = true;
		} catch (error) {
			let msg = "Failed to redirect to the Autodesk logout page."
			console.error(msg, error);
			CP.showErrorMessage(msg);
		}
	}
	return redirected;
};

// Checks the index file and looks for a scene entry. If one is found
// the scenes tab it enabled.
const showSceneTabIfScenePresent = async () => {
	let foundScene = false;
	if (isLinked()) {
		let indexFile = await getIndexFile(null);
		for (const key in indexFile.Entries) {
			let entry = indexFile.Entries[key];
			if (entry.File?.ArkioScene != null) {
				foundScene = true;
				break;
			}
		}
	}
	setVisibility(getel('sceneListItem'), foundScene);
};

// Get the base URL of the site
// Example: http://localhost:1234
const getSiteBaseUrl = () => { return `${window.location.protocol}//${window.location.host}`; };

// Base encodes an array of bytes and then URI encodes the base64 encoded string
const getBytesAsURIComponent = (bytes) => {return encodeURIComponent(CryptoUtil.bytesToBase64(bytes)); };

// A way to copy text to clipboard that works in older browsers
// Returns true if successfull
const fallbackCopyToClipboard = function (text) {
    var textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
    textarea.style.opacity = '0';  // Ensure the textarea is not visible.
    
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

	let success = false;
    try {
        success = document.execCommand('copy');
        console.log('fallbackCopyToClipboard: Copying text command was ' + (success ? 'successful' : 'unsuccessful'));
		return success;
    } catch (err) {
        console.error('fallbackCopyToClipboard: Unable to copy text', err);
    }

    document.body.removeChild(textarea);

	return success;
};

// Copies text to clipboard
const copyToClipboard = async function (text) {
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Text copied to clipboard successfully!');
            return true;
        } catch (error) {
            console.error('Failed to copy text: ', error);
            return false;
        }
    } else {
        // Fallback method
        return fallbackCopyToClipboard(text);
    }
};

